.App {
  position: relative;
  text-align: center;
  font-family: sans-serif;
  height: 100vh;
  width: 100vw;
  background: #0c0c0c;

}

.Logo {
  position: absolute;
  top: 43px;
  left: 50%;
  width: 260px;
  transform: translateX(-50%);
}

.Logo2 {
  position: absolute;
  bottom: 43px;
  left: 50%;
  width: 260px;
  transform: translateX(-50%);
}


